import {Module} from 'vuex';
import {ApplicationState} from '@/store/modules/application/types';
import {RootState} from '@/store/types';
import {mutations} from '@/store/modules/application/mutations';
import {actions} from '@/store/modules/application/actions';


export const application: Module<ApplicationState, RootState> = {
    namespaced: true,
    state: {
        myApplications: [],
        applications: [],
        application: null
    },
    actions,
    mutations
};
