<template>
    <div class="is-tp-loading">
        <img class="fade-in-out" src="@/assets/mono.svg" alt="Teleperformance Logo"/>
    </div>
</template>

<style lang="scss">
    .is {
        &-tp {
            &-loading {
                display: flex;
                width: 100vw;
                height: 100vh;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .2s ease-out;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .fade-in-out {
        animation: fadeinout 1.5s infinite;
    }

    @keyframes fadeinout {
        0%, 100% {opacity: 0.2;}
        50% { opacity: 1;}
    }

    @-webkit-keyframes fadeinout {
        0%, 100% {opacity: 0.2;}
        50% { opacity: 1;}
    }

    @-moz-keyframes fadeinout {
        0%, 100% {opacity: 0.2;}
        50% { opacity: 1;}
    }
</style>
