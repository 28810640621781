<template>
    <div class="modal is-active">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title"></p>
                <button class="delete" aria-label="close" @click="close"></button>
            </header>
            <section class="modal-card-body">
                <div class="columns mt-0">
                    <div class="column is-narrow">
                        <figure class="image is-96x96">
                            <img v-if="userImg" class="is-squircle" :src="userImg" alt="User Image"
                                 data-test="user-img"/>
                            <img v-else class="is-squircle nophoto" src="@/assets/llama.svg" alt="Logout"
                                 data-test="logout-img"/>
                        </figure>
                    </div>
                    <div class="column is-stack is-justify-flex-end">
                        <p class="title is-2 mt-5 mb-0" data-test="displayName">{{ me.displayName }}</p>
                        <p class="is-size-5" data-test="userPrincipalName">{{ me.userPrincipalName }}</p>
                    </div>
                </div>
                <div class="columns mt-5">
                    <div class="column">
                        <a :href="accountUrl" target="_blank" class="button is-link is-outlined is-small"
                           data-test="account-url">
                            Manage Account
                        </a>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <div class="columns is-mobile is-aligned-flex-end">
                    <div class="column">
                        <div class="columns pb-3">
                            <div class="column is-narrow pt-1 pb-0">
                                <a class="is-light-link"
                                   href="https://www.teleperformance.com/en-us/data-privacy-information-and-inquires/"
                                   target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="column is-narrow">
                        <div class="buttons is-right">
                            <button class="button is-danger is-light" @click='logout'>Log Out</button>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script type="ts">
import {useStore} from 'vuex';
import {defineComponent, computed} from 'vue';

export default defineComponent({
    name: 'UserModal',
    setup (_, {emit})
    {
        const store = useStore();
        const me = computed(() => store.state.user.me);
        const userImg = computed(() => store.state.user.userImg);
        const accountUrl = process.env.VUE_APP_ACCOUNT_APP_URL;

        function close ()
        {
            emit('close');
        }

        function logout ()
        {
            store.dispatch('logout', null, {root: true});
        }

        return {me, userImg, accountUrl, close, logout};
    }
});
</script>
