import {ActionTree} from 'vuex';
import {Application, ApplicationState} from '@/store/modules/application/types';
import {RootState} from '@/store/types';
import identity from '@/api/identity';
import {ApplicationMutations} from '@/store/modules/application/mutations';


export const actions: ActionTree<ApplicationState, RootState> = {

    async getApplications ({commit})
    {
        try
        {
            const apps = (await identity.adminGetAllApplications()).data;

            const myApplications = apps.filter((app: Application) => app.isOwned);
            const applications = apps.filter((app: Application) => !app.isOwned);

            if (myApplications.length > 0)
            {
                commit(`application/${ApplicationMutations.SET_APPLICATIONS}`, {
                    owner: true,
                    applications: myApplications
                }, {root: true});
            }
            if (applications.length > 0)
            {
                commit(`application/${ApplicationMutations.SET_APPLICATIONS}`, {applications}, {root: true});
            }

        }
        catch (e)
        {
            throw e;
        }
    }

};
