import {Module} from 'vuex';
import {UserState} from '@/store/modules/user/types';
import {RootState} from '@/store/types';
import {mutations} from '@/store/modules/user/mutations';
import {getters} from '@/store/modules/user/getters';
import {actions} from '@/store/modules/user/actions';

export const user: Module<UserState, RootState> = {
    namespaced: true,
    state: {
        obtainedAccessTokens: false,
        accessTokens: {},
        roles: [],
        tpId: '',
        me: {},
        userImg: null
    },
    mutations,
    getters,
    actions
};
