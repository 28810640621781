import {defineRule, configure} from 'vee-validate';
import AllRules from '@vee-validate/rules';
import en from '@vee-validate/i18n/dist/locale/en.json';
import {localize} from '@vee-validate/i18n';

Object.keys(AllRules)
    .forEach((rule) =>
    {
        defineRule(rule, AllRules[rule]);
    });

defineRule('mfa', (value: string) =>
{
    return /^[0-9]{6}$/i.test(value);

});
defineRule('alpha_point', (value: string) =>
{
    return /^[a-zA-Z0-9-._]+$/i.test(value);
});

defineRule('letter_first', (value: string) =>
{
    return /^[A-Za-z][A-Za-z0-9-_.]*(?:-_.[A-Za-z0-9-_.]+)*$/i.test(value);
});
// admin
defineRule('uuid', (value: string) =>
{
    if (!/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value))
    {
        return 'This field must be a valid UUID';
    }
    return true;
});
defineRule('url', (value: string) =>
{
    if (!/^(http:\/\/|https:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(value))
    {
        return 'This field must be a valid URL';
    }
    return true;
});
defineRule('upn', (value: string) =>
{
    if (!value.includes('@'))
    {
        return 'This field must be a valid Teleperformance ID';
    }
    return true;
});

(() =>
{
    configure({
        validateOnInput: true,
        validateOnChange: true,
        validateOnModelUpdate: true,
        validateOnBlur: true,
        generateMessage: localize({
            en: {
                messages: {
                    ...en.messages,
                    'mfa': 'The {field} field must have 6 numeric characters',
                    'letter_first': 'The {field} field must begin with a letter',
                    'alpha_point': 'The {field} field may only contain alpha-numeric characters as well as points, dashes and underscores',
                }
            }
        })
    });
})();
