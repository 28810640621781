import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router';
import store, {AppMutations} from '@/store';

const routes: Array<RouteRecordRaw> = [
    /*{
        path: '/',
        name: 'Applications',
        meta: {
            layout: 'appLayout'
        },
        component: ()=> import('@/views/Applications.vue')
    },*/
    // standard login path
    {
        path: '/login/:id',
        name: 'LoginBlanco',
        meta: {
            layout: 'appLayout'
        },
        component: ()=> import('@/views/LoginBlanco.vue')
    },
    // vanity login URLs
    {
        // mytp-platform
        path: '/mytp',
        redirect: '/login/12bef5bc-24dc-4645-8cf3-e4e73ad2fa83',
    },
    {
        // mytp
        path: '/mytp/wfm',
        redirect: '/login/d5f85699-09e4-49e7-b156-bcede629b308',
    },
    {
        // ccms
        path: '/mytp/hr',
        redirect: '/login/ca44087e-0ff9-4b83-8c3a-6b6bca627999',
    },
    {
        // saba
        path: '/mytp/learning',
        beforeEnter: () =>
        {
            window.location.href = 'https://teleperformance-mytp.sabacloud.com';
        },
        component: () => import('@/components/admin/Lazy.vue'),
    },
    // internal MFA
    {
        path: '/mfa/:id',
        name: 'MFA',
        meta: {
            layout: 'appLayout'
        },
        component: ()=> import('@/views/MFA.vue')
    },
    {
        path: '/mfa-reset',
        name: 'MFAReset',
        meta: {
            layout: 'appLayout'
        },
        component: ()=> import('@/views/MFAReset.vue')
    },
    // error handling
    {
        path: '/error/:code/:message',
        name: 'Error',
        meta: {
            layout: 'appLayout'
        },
        component: ()=> import('@/views/Error.vue')
    },
    // identity admin area
    {
        // Path for admin routes
        path: '/admin',
        name: 'AdminHome',
        component: () => import('@/views/admin/Home.vue'),
        meta: {
            admin: true,
            layout: 'adminLayout'
        }
    },
    {
        // Path for admin routes
        path: '/admin/allowedDomains',
        name: 'AdminAllowedDomains',
        component: () => import('@/views/admin/AllowedDomains.vue'),
        meta: {
            admin: true,
            layout: 'adminLayout'
        }
    },
    {
        path: '/admin/applications/new',
        name: 'AdminIdentityAppNew',
        meta: {
            admin:  true,
            layout: 'adminLayout'
        },
        component: () => import('@/views/admin/NewIdentityApp.vue')
    },
    {
        path: '/admin/applications/:id',
        name: 'AdminIdentityAppDetails',
        meta: {
            admin:  true,
            layout: 'adminLayout'
        },
        component: () => import('@/views/admin/IdentityAppDetails.vue')
    },
    {
        path: '/admin/logs',
        name: 'LogsRecords',
        meta: {
            logs: true,
            layout: 'adminLayout'
        },
        component: () => import('@/views/admin/LogsRecords.vue')
    },

    {
        path: '/admin/stats',
        name: 'Stats',
        meta: {
            logs: true,
            layout: 'adminLayout'
        },
        component: () => import('@/views/admin/Stats.vue')
    },
    // logout
    {
        path: '/logout',
        name: 'ApplicationsLogout',
        meta: {
            layout: 'appLayout'
        },
        component: () => import('@/views/ApplicationsLogout.vue')
    },
    // catch
    {
        path: '/:pathMatch(.*)', redirect: '/error/404/Page%20not%20found.'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to:RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) =>
{
    document.title = 'Identity Platform';
    if (to.meta.admin || to.meta.logs)
    {
        store.commit(AppMutations.SET_ALLOWED, false, {root: true});
        document.title = 'Identity Platform';
        const accessTokens = await store.dispatch('user/getAccessTokens', null, {root: true});
        if(!Object.keys(accessTokens).length)
        {
            next({name: 'LoginBlanco', params: {id: process.env.VUE_APP_IDENTITY_PLATFORM_ID}});
        }
        else
        {
            await store.commit(AppMutations.SET_ALLOWED, true);
            next();
        }
    }
    else
    {
        next();
    }
});

export default router;
