
import {defineComponent, computed} from 'vue';
import Admin from '@/layouts/Admin.vue';
import Application from '@/layouts/Application.vue';
import {useRoute} from 'vue-router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export default defineComponent({
    components:{
        adminLayout: Admin,
        appLayout: Application
    },
    setup ()
    {
        const route = useRoute();
        const layout =  computed(()=> route.meta.layout);

        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: process.env.VUE_APP_INSTRUMENTATION_KEY,
                enableAutoRouteTracking: true,
                autoTrackPageVisitTime: true,
                /* ...Other Configuration Options... */
            } });
        appInsights.loadAppInsights();
        appInsights.trackPageView();

        return {layout};
    }
});
