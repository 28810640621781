import axios, {AxiosResponse} from 'axios';
import store from '@/store';
import {Application, RedirectUrl, Domain} from '@/types/Application';

const URL = process.env.VUE_APP_IDENTITY_URL;
export const api = axios.create({
    baseURL: `${URL}api`,
    withCredentials: true
});
let counter = 0;

let accessToken = null;

function getHeaders ()
{
    const accessToken = store.getters['user/getAccessTokens'].identity;
    return {
        'Authorization': `Bearer ${accessToken}`
    };
}

api.interceptors.response.use(function (response)
{

    return response;
}, async function (error)
{
    if (error)
    {
        const originalRequest = error.config;
        if (!originalRequest.url.startsWith('public') && error.response.status === 401 && counter < 3)
        {
            counter++;
            await store.dispatch('user/fetchAccessTokens', null, {root: true});
            accessToken = store.getters['user/getAccessTokens'].identity;
            originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
            return api(originalRequest);
        }
        else
        {
            return Promise.reject(error);
        }
    }
    else
    {
        counter = 0;
    }
});

export default {
    URL,
    getApplications (search?: string): Promise<AxiosResponse>
    {
        return api.get('public/applications', {params: {search}});
    },
    getApplication (applicationId: string): Promise<AxiosResponse>
    {
        return api.get(`public/applications/${applicationId}`);
    },
    getUser (applicationId: string): Promise<AxiosResponse>
    {
        return api.get('public/me', {params: {applicationId}});
    },
    login (username: string, applicationId: string, redirectUrl?: string): Promise<AxiosResponse>
    {
        return api.post('public/login', {username, applicationId, redirectUrl});
    },
    getCCMS (applicationId: string): Promise<AxiosResponse>
    {
        return api.get(`public/ccms/${applicationId}`);
    },
    linkCCMS (applicationId: string, username: string | null, password: string | null): Promise<AxiosResponse>
    {
        return api.post('public/ccms', {applicationId, username, password});
    },
    getMFA (applicationId: string): Promise<AxiosResponse>
    {
        return api.get(`public/mfa/${applicationId}`);
    },
    verifyMFA (applicationId: string, token: string): Promise<AxiosResponse>
    {
        return api.post('public/mfa', {applicationId, token});
    },
    resetMFA (username: string): Promise<AxiosResponse>
    {
        return api.patch('public/mfa', {username});
    },
    adminGetAllApplications (): Promise<AxiosResponse>
    {
        return api.get('applications', {headers: getHeaders()});
    },
    adminGetSingleApplication (id: string): Promise<AxiosResponse>
    {
        return api.get(`applications/${id}`, {headers: getHeaders()});
    },
    adminUpdateApplication (data: Application): Promise<AxiosResponse>
    {
        return api.put(`applications/${data.id}`, data, {headers: getHeaders()});
    },
    adminCreateUrl (data: RedirectUrl, id: string): Promise<AxiosResponse>
    {
        return api.post(`applications/${id}/redirectUrls`, data, {headers: getHeaders()});
    },
    adminDeleteUrl (applicationId: string, urlId: string): Promise<void>
    {
        return api.delete(`applications/${applicationId}/redirectUrls/${urlId}`, {headers: getHeaders()});
    },
    adminCreateApplication (data: Application): Promise<AxiosResponse>
    {
        return api.post('applications', data, {headers: getHeaders()});
    },
    adminDeleteApplication (id: string): Promise<void>
    {
        return api.delete(`applications/${id}`, {headers: getHeaders()});
    },
    adminGetAllowedDomains (): Promise<AxiosResponse>
    {
        return api.get('allowed-domains', {headers: getHeaders()});
    },
    adminCreateAllowedDomains (data: Domain): Promise<AxiosResponse>
    {
        return api.post('allowed-domains', data, {headers: getHeaders()});
    },
    adminUpdateAllowedDomains (id: string, data: Domain): Promise<AxiosResponse>
    {
        return api.put(`allowed-domains/${id}`, data, {headers: getHeaders()});
    },

    adminDeleteAllowedDomains (id: string): Promise<void>
    {
        return api.delete(`allowed-domains/${id}`, {headers: getHeaders()});
    },

    logout (): Promise<AxiosResponse>
    {
        return api.get('logout');
    },
    getStatsLogs (queryString: string): Promise<AxiosResponse>
    {
        return api.get(`logs/stats?${queryString}`, {headers: getHeaders()});
    },
    getLogs (queryString: string): Promise<AxiosResponse>
    {
        return api.get(`logs?${queryString}`, {headers: getHeaders()});
    }
};
