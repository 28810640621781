
import {useStore} from 'vuex';
import {defineComponent, reactive, computed, toRefs, onMounted} from 'vue';
import Beta from '@/components/admin/Beta.vue';
import Lazy from '@/components/admin/Lazy.vue';
import NavigationModal from '@/components/admin/NavigationModal.vue';
import UserModal from '@/components/admin/UserModal.vue';

export default defineComponent({
    name: 'Admin',
    components: {
        Beta,
        Lazy,
        NavigationModal,
        UserModal
    },
    setup ()
    {
        const store = useStore();

        const content = reactive({
            showBeta: false,
            showUserMenu: false,
            showNavigationMenu: false,
            userImg: computed(() => store.state.user.userImg),
            me: computed(() => store.state.user.me),
            allowed: computed(() => store.getters.getAllowed),
            roles: computed(() => store.state.user.roles)
        });


        function hamburgerHelper ()
        {
            content.showNavigationMenu = !content.showNavigationMenu;
        }

        function openUserMenu ()
        {
            content.showNavigationMenu = false;
            content.showUserMenu = true;
        }

        function openBeta ()
        {
            content.showBeta = true;
        }

        function onResize ()
        {
            // have to dynamically handle the height of the menu
            // bar beacuse on mobile the viewport height changes
            // as the bars show/hide on scroll
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        function isAllowed (rol: string)
        {
            return content.roles.includes(rol);
        }

        onMounted(() =>
        {
            onResize();
            window.addEventListener('resize', onResize);
        });

        return {...toRefs(content), hamburgerHelper, openUserMenu, openBeta, isAllowed};
    }
});
