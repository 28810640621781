import {GetterTree} from 'vuex';
import {AccessTokens, UserState} from '@/store/modules/user/types';
import {RootState} from '@/store/types';

export const getters: GetterTree<UserState, RootState> = {
    getAccessTokens (state): AccessTokens
    {
        return state.accessTokens;
    },
    getRoles (state): string[]
    {
        return state.roles;
    }
};
