<template>
    <div class="modal is-active">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title"></p>
                <button class="delete" aria-label="close" @click="close"></button>
            </header>
            <section class="modal-card-body">
                <p class="title is-2">Beta</p>
                <p>This software is currently in beta. While being feature complete, you may experience some issues that
                    decrease the reliability of the application.</p>
                <p class="title is-6 mt-5 mb-2">Inconsistent Results</p>
                <p>The features and functionality of this application have been built to specific use cases. Often, when
                    a product is released, we find edge cases that may not have been considered during our initial
                    development. During this phase, we work to capture all of these edge cases to ensure the
                    functionality provided works under all circumstances.</p>
                <p class="title is-6 mt-5 mb-2">Unhandled Errors</p>
                <p>In most cases, reading or writing data actually involves a chain of events that have to all work
                    successfully in order to complete a task. While we strive to handle all possible failures along that
                    chain, sometimes there is a break where we don’t expect it. This may result in a spinner that never
                    stops spinning or a button click that leaves you hanging. During this phase, we work to track down
                    all these breaks so that we can handle them appropriately.</p>
                <p class="title is-6 mt-5 mb-2">Speed or Performance Issues</p>
                <p>During initial development, our focus is on getting things working. During this phase, we will be
                    reviewing usage and exploring options to create efficiencies that speed up your experience.</p>
                <p class="title is-6 mt-5 mb-2">Design Enhancements</p>
                <p>We strive to provide the best possible experience. This is a continually evolving process where
                    refinements are made as we learn more about how our users interact with our software. During this
                    phase, we work to quickly incorporate what we learn to enhance the user experience.</p>
            </section>
            <footer class="modal-card-foot"></footer>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Beta',
    setup (_, {emit})
    {
        function close ()
        {
            emit('close');
        }

        return {close};
    }
});
</script>
