import {MutationTree} from 'vuex';
import {ApplicationState} from '@/store/modules/application/types';


export enum ApplicationMutations {
    SET_APPLICATIONS = 'SET_APPLICATIONS',
    SET_APPLICATION = 'SET_APPLICATION'
}

export const mutations: MutationTree<ApplicationState> = {
    [ApplicationMutations.SET_APPLICATIONS] (state, payload)
    {
        if(payload.owner) state.myApplications = payload.applications;
        else state.applications = payload.applications;
    },
    [ApplicationMutations.SET_APPLICATION] (state, payload)
    {
        state.application = payload;
    }
};
