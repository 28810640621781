<template>
    <div class="modal is-active">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title"></p>
                <button class="delete" aria-label="close" @click="close"></button>
            </header>
            <section class="modal-card-body">
                <div class="columns is-mobile">
                    <div class="column is-narrow">
                        <figure class="image is-64x64">
                            <img src="@/assets/mono.svg" alt="Teleperformance logo"/>
                        </figure>
                    </div>
                    <div class="column">
                        <p class="title is-4">Teleperformance<br>Application Platform</p>
                    </div>
                </div>
                <div class="columns mt-5">
                    <aside class="menu is-tp-menu is-modal">
                        <ul class="menu-list">
                            <li v-if="isAllowed('App.Manage')">
                                <router-link active-class="is-active" exact @click="close" :to="{name: 'AdminHome'}">
                                    Home
                                </router-link>
                            </li>
                            <li v-if="isAllowed('AllowedDomain.Manage')">
                                <router-link active-class="is-active mt-2" exact @click="close"
                                             :to="{name: 'AdminAllowedDomains'}">Allowed domains
                                </router-link>
                            </li>
                        </ul>
                        <p class="menu-label">Logs</p>
                            <ul class="menu-list">
                                <li v-if="isAllowed('log.read')">
                                    <router-link active-class="is-active mt-3" :to="{name: 'LogsRecords'}">Log records</router-link> 
                                </li>
                                <li v-if="isAllowed('log.read')">
                                    <router-link active-class="is-active mt-3" :to="{name: 'Stats'}">Stats</router-link> 
                                </li>
                            </ul>
                    </aside>
                </div>
            </section>
            <footer class="modal-card-foot">
                <div class="columns is-mobile is-aligned-flex-end">
                    <div class="column is-narrow" @click="openUserMenu">
                        <figure class="image is-48x48">
                            <img v-if="userImg" class="is-squircle" :src="userImg" data-test="user-img"/>
                            <img v-else class="is-squircle nophoto" src="@/assets/llama.svg" data-test="no-photo"/>
                        </figure>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script type="ts">
import {useStore} from 'vuex';
import {defineComponent, computed, reactive} from 'vue';

export default defineComponent({
    name: 'NavigationModal',
    emits: ['close', 'openUserMenu'],
    setup (_, {emit})
    {
        const store = useStore();
        const userImg = computed(() => store.state.user.userImg);

        const data = reactive({
            roles: computed(() => store.state.user.roles)
        });

        function close ()
        {
            emit('close');
        }

        function isAllowed (rol)
        {
            return data.roles.includes(rol);
        }

        function openUserMenu ()
        {
            emit('openUserMenu');
        }

        return {userImg, close, openUserMenu, isAllowed};
    }
});
</script>
