import {ActionTree} from 'vuex';
import axios from 'axios';
import {AccessTokens, UserState} from '@/store/modules/user/types';
import {RootState} from '@/store/types';
import {UserMutations} from '@/store/modules/user/mutations';

export const actions: ActionTree<UserState, RootState> = {
    async getAccessTokens ({state, dispatch}): Promise<AccessTokens>
    {
        if (!Object.keys(state.accessTokens).length)
        {
            await dispatch('fetchAccessTokens');
            await dispatch('initialize', null, {root: true});
        }
        return state.accessTokens;
    },
    async fetchAccessTokens ({commit})
    {
        try
        {
            axios.defaults.withCredentials = true;
            const tokenRequest = await axios.get(process.env.VUE_APP_MSAL_URL);
            const accessTokens: AccessTokens = {};
            let roles: string[] = [];
            const keys = Object.keys(tokenRequest.data.accessTokens);
            keys.forEach(key =>
            {
                const token = Reflect.get(tokenRequest.data.accessTokens, key);
                let targets = token.target.toLowerCase();
                targets = targets.split(' ');
                if (targets.includes('user.read'))
                {
                    accessTokens.msGraph = token.secret;
                }
                if (token.aud.includes(process.env.VUE_APP_IDENTITY_AUD))
                {
                    accessTokens.identity = token.secret;
                    const payload = accessTokens.identity?.split('.')[1];
                    const jsonPayload = JSON.parse(atob(payload as string));
                    roles = jsonPayload.roles || [];
                }
            });
            commit(`user/${UserMutations.SET_ACCESS_TOKEN}`, accessTokens, {root: true});
            commit(`user/${UserMutations.SET_ROLES}`, roles, {root: true});
        }
        catch (error)
        {

            window.location.href = process.env.VUE_APP_LOGIN_URL;
            // window.location.href = 'https://login.ontp.app/login/4c027f33-2711-462a-8c2a-b9c3de784b97?redirectUrl=http%3A%2F%2Flocalhost%3A8888%2Fadmin';
        }
    }
};
