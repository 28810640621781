import {MutationTree} from 'vuex';
import {UserState} from '@/store/modules/user/types';

export enum UserMutations {
    SET_OBTAINED_ACCESS_TOKENS = 'SET_OBTAINED_ACCESS_TOKENS',
    SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
    SET_ROLES = 'SET_ROLES',
    SET_ME = 'SET_ME',
    SET_USER_IMAGE = 'SET_USER_IMAGE',
    SET_TP_ID = 'SET_TP_ID'
}

export const mutations: MutationTree<UserState> = {
    [UserMutations.SET_OBTAINED_ACCESS_TOKENS] (state, payload)
    {
        state.obtainedAccessTokens = payload;
    },
    [UserMutations.SET_ACCESS_TOKEN] (state, payload)
    {
        state.accessTokens = payload;
    },
    [UserMutations.SET_ME] (state, payload)
    {
        state.me = payload;
    },
    [UserMutations.SET_USER_IMAGE] (state, payload)
    {
        state.userImg = payload;
    },
    [UserMutations.SET_TP_ID] (state, payload)
    {
        state.tpId = payload;
    },
    [UserMutations.SET_ROLES] (state, payload)
    {
        state.roles = payload;
    },
};
